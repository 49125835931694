
import { defineComponent } from "vue";
import {
  ApiHelper,
  ApiService,
  ButtonComponent,
  Dictionary,
  Logger,
  ModalComponent,
  NotificationsService,
  TABLE_ICONS,
  TInputComponent,
  TSelectComponent,
} from "table";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";
import { ApiBadRequest } from "@/common/types/api";
import FormHelper, { FormHelperModel } from "@/common/helpers/FormHelper";
import { AuthenticationService } from "@/services/AuthenticationService";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";

export interface SelectedReportForm {
  selectValue?: ApiSelectValue;
  form_period_start?: string;
  form_period_end?: string;
}

function getInitData() {
  return {
    report_type: null as ApiSelectValue | null,
    reportingForms: [{}] as SelectedReportForm[],
    author: null as ApiSelectValue | null,
  };
}

export default defineComponent({
  name: "CreateReportFormModal",
  emits: ["close", "created-report"],
  components: {
    TSelectComponent,
    ModalComponent,
    ButtonComponent,
    TInputComponent,
  },
  props: {
    journalName: String,
    preset: String,
  },
  setup() {
    return {
      AuthenticationService,
      ApiService,
    };
  },
  data() {
    return {
      TABLE_ICONS,
      formData: getInitData() as ReturnType<typeof getInitData>,
      model: {
        author: {
          required: true,
        },
        report_type: {
          required: true,
        },
        reportingForms: {
          required: true,
          validator(reportingForms: SelectedReportForm[]) {
            const reportingFormsFilter = reportingForms.filter(
              (x) => x.selectValue,
            );
            if (reportingFormsFilter.length === 0) {
              NotificationsService.send({
                type: "error",
                title: "Ошибка при валидации формы",
                text: "Заполните отчётные формы",
                duration: 5000,
              });
              return { valid: false };
            }

            return { valid: true };
          },
        },
      } as Dictionary<FormHelperModel>,
    };
  },
  computed: {
    reportingFormsExcluded(): ApiSelectValue[] {
      return this.formData.reportingForms
        .filter((x) => !!x.selectValue)
        .map((x) => x.selectValue!);
    },
  },
  mounted() {
    this.setDefaultOrg();
  },
  methods: {
    setDefaultOrg() {
      const user = AuthenticationService.user.value;
      if (user && user.default_org) {
        this.formData.author = user.default_org;
      }
    },

    async onCreateReport() {
      const { errors, newModelDict } = FormHelper.getErrors(
        this.model,
        this.formData,
      );
      if (errors.length) {
        this.model = newModelDict;
        return;
      }

      try {
        // const reportResult = (
        //   await ApiServiceRegReports.saveReport({
        //     report_type: this.formData.report_type!.value,
        //     author_id: Number(this.formData.author!.value),
        //     author_name: this.formData.author!.label,
        //   })
        // ).json;

        const saveFormResult = await ApiServiceRegReports.saveForm(
          this.formData.reportingForms
            .filter((x) => !!x.selectValue)
            .map((x, i: number) => ({
              author_id: this.formData.author!.value,
              author_name: this.formData.author!.label,
              form_no: x.selectValue!.value,
              form_name: x.selectValue!.label,
              // form_period_start: x.form_period_start,
              // form_period_end: x.form_period_end,
            })),
        );
        // this.$emit("created-report", reportResult);
        if (saveFormResult.json.result) {
          await this.$router.push(
            `/editor-forms/${saveFormResult.json.result[0].report_form_id}`,
          );
        }
      } catch (e: any) {
        if (e.status === 400) {
          const response = e as Response;
          const json: ApiBadRequest = await response.json();
          json.errors.forEach((x) =>
            x.validation_errors?.types_errors?.forEach((typeError) => {
              this.model[typeError.attr].error = typeError.rus_message;
            }),
          );
          Logger.error({
            response,
            json,
          });
        } else {
          const errorMessage = await ApiHelper.getErrorMessage(e);
          NotificationsService.send({
            type: "error",
            title: "Произошла ошибка при создании отчёта",
            text: errorMessage,
          });
          Logger.error({ e });
        }
      }
    },
  },
});
