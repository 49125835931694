
import { defineComponent } from "vue";
import {
  ApiHelper,
  ButtonComponent,
  ModalComponent,
  NotificationsService,
  TABLE_ICONS,
} from "table";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";

export default defineComponent({
  name: "LoadReportForm",
  emits: ["close", "success"],
  components: {
    ModalComponent,
    ButtonComponent,
  },
  data() {
    return {
      TABLE_ICONS,
      data: {
        file: null as null | File,
      },
    };
  },
  methods: {
    onSelectFile(event: InputEvent) {
      const file = (event.target as HTMLInputElement).files![0];
      if (!file) {
        return;
      }

      this.data.file = file;
    },
    async onUpload() {
      if (!this.data.file) {
        return;
      }

      try {
        const result = await ApiServiceRegReports.uploadReport(this.data.file);
        this.$emit("success", result);
      } catch (e) {
        const errorMessage = await ApiHelper.getErrorMessage(e);
        NotificationsService.send({
          type: "error",
          title: "Произошла ошибка при загрузке отчётной формы",
          text: errorMessage,
        });
      }
    },
  },
});
